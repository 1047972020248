import React, {ReactElement} from "react";
import {TextField} from "@mui/material";

interface IProps {
    handleTextFilter: (text: string) => void;
}

export default function PricingProductFilter(props: IProps): ReactElement {
    const {handleTextFilter} = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleTextFilter(event.target.value);
    };

    return (
        <TextField
            id="standard-basic"
            label="Filtruj"
            type="search"
            size="small"
            onChange={handleChange}
            sx={{
                fontSize: 8,
                marginRight: '20px'
            }}
        />
    );
}
