import React, { ReactElement } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { TPositionsFilter } from "../../types";

interface IProps {
  setState: (position: string, positionState: boolean) => void;

  positions: TPositionsFilter;
}

export default function PricingPositionFilter(props: IProps): ReactElement {
  const { setState, positions } = props;
  const handleChecked = (event: { target: any }) => {
    setState(event.target.id, event.target.checked);
  };
  const { PC, AS, SUP, AM, MAN } = positions;

  return (
    <FormGroup sx={{margin: '10px' }}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChecked}
            id="PC"
            checked={PC}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          />
        }
        label="Pref Customer"
        sx={{ "& .MuiTypography-root": { fontSize: 14 } }}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChecked}
            id="AS"
            checked={AS}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          />
        }
        label="Asistant Supervisor"
        sx={{ "& .MuiTypography-root": { fontSize: 14 } }}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChecked}
            id="SUP"
            checked={SUP}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          />
        }
        label="Supervisor"
        sx={{ "& .MuiTypography-root": { fontSize: 14 } }}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChecked}
            id="AM"
            checked={AM}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          />
        }
        label="Asistant Manager"
        sx={{ "& .MuiTypography-root": { fontSize: 14 } }}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChecked}
            id="MAN"
            checked={MAN}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          />
        }
        label="Manager"
        sx={{ "& .MuiTypography-root": { fontSize: 14 } }}
      />
    </FormGroup>
  );
}
