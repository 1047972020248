import React, { ReactElement } from "react";
import products from "./products-latest.json";
import {
  SPricingList,
  SPricingHeader,
  SPricingRow,
  SPricingHeaderCell,
  SPricingColumn,
  SPricingColumnTitle,
} from "./products-list.styles";
import { TPositionsFilter } from "../../types";

interface IProps {
  positionsFiltered: TPositionsFilter;
  textFiltered: string;
}

const ProductsList = (props: IProps): ReactElement => {
  const { positionsFiltered, textFiltered } = props;
  const { PC, AS, SUP, AM, MAN } = positionsFiltered;

  return (
    <SPricingList>
      <tbody>
        <SPricingHeader>
          <SPricingColumnTitle>Produkt</SPricingColumnTitle>
          <SPricingHeaderCell>CC</SPricingHeaderCell>
          <SPricingHeaderCell>Detal</SPricingHeaderCell>
          {PC && <SPricingHeaderCell>PC</SPricingHeaderCell>}
          {AS && <SPricingHeaderCell>AS</SPricingHeaderCell>}
          {SUP && <SPricingHeaderCell>SUP</SPricingHeaderCell>}
          {AM && <SPricingHeaderCell>AM</SPricingHeaderCell>}
          {MAN && <SPricingHeaderCell>MAN</SPricingHeaderCell>}
        </SPricingHeader>
        {products
          .filter((product) =>
            product.Produkt.match(new RegExp(textFiltered, "i"))
          )
          .map((product) => (
            <SPricingRow key={product.Kod}>
              <SPricingColumnTitle align="left">
                {product.Produkt}
              </SPricingColumnTitle>
              <SPricingColumn align="right">{product.CC}</SPricingColumn>
              <SPricingColumn align="right">{product.Detal}</SPricingColumn>
              {PC && (
                <SPricingColumn align="right">{product.PC}</SPricingColumn>
              )}
              {AS && (
                <SPricingColumn align="right">{product.AS}</SPricingColumn>
              )}
              {SUP && (
                <SPricingColumn align="right">{product.SUP}</SPricingColumn>
              )}
              {AM && (
                <SPricingColumn align="right">{product.AM}</SPricingColumn>
              )}
              {MAN && (
                <SPricingColumn align="right">{product.MAN}</SPricingColumn>
              )}
            </SPricingRow>
          ))}
      </tbody>
    </SPricingList>
  );
};

export default ProductsList;
