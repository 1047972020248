import styled from "styled-components";

export const SPricingList = styled.table`
  padding: 2px;
  width: 100%;
  font-size: 11px;
  border: 0;
  border-spacing: 0;
  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

export const SPricingHeader = styled.tr`
  font-weight: bold;
  &:hover {
    background: #eeeeee;
    td {
      background: #eeeeee;
    }
  }
`;

export const SPricingRow = styled.tr`
  &:hover {
    background: #eeeeee;
    td {
      background: #eeeeee;
    }
  }
`;

export const SPricingHeaderCell = styled.td`
  text-align: right;
  border-bottom: 1px solid #eeeeee;
  padding: 1px 2px;
`;

export const SPricingColumn = styled.td`
  text-align: right;
  border-bottom: 1px solid #eeeeee;
  padding: 1px 2px;
`;

export const SPricingColumnTitle = styled.td`
  text-align: left;
  border-bottom: 1px solid #eeeeee;
  padding: 1px 2px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
