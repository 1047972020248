import React, { ReactElement } from "react";
import styled from "styled-components";

interface IProps {
  type: 'diamonds'|'forever';
}

const SImg = styled.img`
  height: 60px;
`;

export default function Logo(props: IProps): ReactElement {
  const { type } = props;

  const src = type === 'forever' ? '/assets/eagle_white.png' : '/assets/diamonds_white.png';

  return (
    <SImg src={src} />
  );
}
