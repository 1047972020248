import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, Box, createTheme, Drawer, IconButton, ThemeProvider, Toolbar} from "@mui/material";

import ProductsList from "../components/products-list/products-list";
import PricingPositionFilter from "../components/pricing-position-filter/pricing-position-filter";
import PricingProductFilter from "../components/pricing-product-filter/pricing-product-filter";
import {useCookies} from "react-cookie";
import {TPositionsFilter} from "../types";
import Logo from "../components/logo/logo";

const drawerWidth = 240;

function App() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const [positionCookie, setCookie] = useCookies(["positions"]);

    const initialPositions: TPositionsFilter = positionCookie.positions ?? {
        PC: false,
        AS: false,
        SUP: false,
        AM: false,
        MAN: false,
    };

    const [positionsFiltered, setPositionsFiltered] =
        React.useState(initialPositions);

    const handlePositionsFiltered = (position: string, checked: boolean) => {
        const positionsToChange = {
            ...positionsFiltered,
            [position]: checked,
        };
        setPositionsFiltered(positionsToChange);
        setCookie("positions", positionsToChange, {path: "/"});
    };

    const [textFiltered, setTextFiltered] = React.useState("");

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{display: "flex"}}>
                <AppBar position="fixed">
                    <Toolbar sx={{minHeight: 50, gap: '10px'}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <PricingProductFilter handleTextFilter={setTextFiltered}/>
                        <Logo type="diamonds"/>
                        <Logo type="forever"/>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                            },
                        }}
                    >
                        <PricingPositionFilter
                            positions={positionsFiltered}
                            setState={handlePositionsFiltered}
                        />
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: "100%",
                        padding: "4px",
                        "@media screen and (min-width: 768px)": {
                            padding: "8px",
                        },
                    }}
                >
                    <Toolbar/>
                    <ProductsList
                        positionsFiltered={positionsFiltered}
                        textFiltered={textFiltered}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default App;
